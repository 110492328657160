@use "sass:math";@import "~@/common/styles/variables.scss";@import './default.scss';

.site-footer{
  height: 230px;
  background-color: $base-color-red;
  color: #fff;
  align-items: center;
  display: flex;
  .content{
    font-size: 14px;
    padding: 10px;
    width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    .fl{
      padding: 5px;
      background-color: #fff;
      color: $base-color-red;
    }
    .item{
      margin-bottom: 10px;
    }
    .ba{
     
    }
  }
  
}
.site-header{
}
.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 1000;
  // padding: 5px;
  // background-color: #cae8ca;
  // border: 2px solid #4caf50;
}
a{
  text-decoration: none;
  font-style: normal;
  cursor: pointer;
  color: #000000;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.dh,.img,.title,.overimg{
  transition: $base-transition-all;
}
.overimg:hover{
  transform: scale(1.1,1.1);
}
/*过度动画*/
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: $base-transition;
}

.fade-transform-enter {
  opacity: 0;
}

.fade-transform-leave-to {
  opacity: 0;
}


@media (min-width: 993px) and (max-width: 1200px){
  html{
    font-size: 14px;
  }
  .header-logo{
    display: none !important;
  }
}
@media (min-width: 576px) and (max-width: 992px){
  html{
    font-size: 12px;
  }
  .index-container .home-content .home-menu .menu-list .menu-item  
  {
    >img{
      height: 140px !important;
      width: 140px !important;
    }
    .title{
      font-size: 16px !important;
    }
  }
  // .tip-x {
  //   .image{
  //     width: 40px !important;
  //     height: 30px !important;
  //   }
  //   .title{
  //     font-size: 24px !important;
  //   }
  // }
  
  .site-header{
    .header-logo{
      display: none !important;
    }
    .header-menu{
      margin-left: 0px !important;
      .mobile-menu-icon{
        display: inline-block !important;
      }
      .menu-list{
        display: none !important;
      }
    }
    .header-btn-tool{
      margin:10px 0px !important;
    }
  }
  
  
}
@media (max-width: 575px){
  html{
    font-size: 10px;
  }
  .index-container .home-content .home-menu .menu-list .menu-item  
  {
    >img{
      height: 100px !important;
      width: 100px !important;
    }
    .title{
      font-size: 14px !important;
    }
  }
  // .tip-x {
  //   .image{
  //     width: 25px !important;
  //     height: 20px !important;
  //   }
  //   .title{
  //     font-size: 20px !important;
  //   }
  // }
  .site-header{
    .header-logo{
      display: none !important;
    }
    .header-menu{
      margin-left: 0px !important;
      .mobile-menu-icon{
        display: inline-block !important;
      }
      .menu-list{
        display: none !important;
      }
    }
    .header-btn-tool{
      margin:10px 0px !important;
    }
    // .header-btn-tool{
    //   .btn-tool-reg{
    //     display: none !important;
    //   }
    // }
  }
}
// @media (max-width: 320px){
//   .index-container .home-content .home-menu .menu-list .menu-item  
//   {
//     >img{
//       height: 100px !important;
//       width: 100px !important;
//     }
//     .title{
//       font-size: 14px !important;
//     }
//   }
// }
.agenda-tabs {
  margin-top: 20px;
  .el-tabs__nav {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .el-tabs__item {
      color: #fff;
      width: 100%;
      &.is-active {
        font-weight: bold;
        background: #fff;
        padding: 0;
        color: #ca3935;
      }
    }
  }
}