@use "sass:math";@import "~@/common/styles/variables.scss";
.tip-x {
  width: 100%;
  display: flex;
  align-items: center;
  .title {
    font-size: $font35;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-left: 10px;
  }
  .title-white {
    color: #fff !important;
  }
  .image {
    width: $px60;
  }
}
