@use "sass:math";@import "~@/common/styles/variables.scss";
.image-list-contianer {
  .image-list-wrapper {
    margin-top: 20px;
    .image-list-item {
      border: 1px solid #999999;
      padding: 10px;
      background: #fff;
      > img {
        object-fit: contain;
        width: 100%;
      }
    }
  }
}
