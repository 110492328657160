@use "sass:math";@import "~@/common/styles/variables.scss";
.mobile-menu {
  position: fixed;
  left: 0;
  top: 0;
  width: 300px;
  padding-right: 30px;
  max-width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 2000;
  .menu-layer {
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all 0.7s ease;
    transform: translateX(-100%);
  }

  .menu-box {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
    transform: translateX(-100%);
    background: #515a6e;
    .menu-close {
      height: 50px;
      position: relative;
      .close {
        // margin: 10px 10px 0px 0px;
        text-align: right;
        font-size: 24px;
        width: 24px;
        color: #ffffff;
        cursor: pointer;
        transition: all 0.9s ease;
        position: absolute;
        top: 10px;
        right: 10px;
        &:hover {
          transform: rotate(360deg);
        }
      }
    }
    .menu-list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      width: 100%;
      .menu-item {
        position: relative;
        display: block;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        a {
          color: #fff;
          display: block;
          padding: 10px 25px;
          font-size: 15px;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
    .content {
      padding: 10px 25px;
      .contact-us {
        color: #fff;
        .txt {
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
.mobile-menu-header {
  .mobile-menu {
    opacity: 1;
    visibility: visible;
    .menu-layer {
      transform: translateX(0%);
    }
    .menu-box {
      opacity: 1;
      visibility: visible;
      transform: translateX(0%);
    }
  }
}
.site-header {
  position: relative;
  text-align: left;
  padding: 10px;
  // height: 70px;
  background-color: $base-color-white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04); //最先写的阴影将显示在最顶层
  z-index: 100;
  width: 100%;
  .header-logo {
    float: left;
    display: block;
  }
  .header-menu {
    float: left;
    margin-left: 10px;
    .mobile-menu-icon {
      font-size: 40px;
      display: none;
      margin-top: 10px;
    }
    .menu-list {
      display: inline-block;
      list-style: none;
      padding: 0;
      margin: 0;
      .menu-item {
        padding: 5px 20px;
        // margin-left: 5px;
        float: left;
        margin-top: 15px;
        &:first-child {
          margin-left: 5px;
        }
        a {
          // transition: $base-transition-all;
          font-weight: 500;
          letter-spacing: 1px;
        }
        &:hover {
          a {
            // font-size: 16px;
            // transform: scale(1.1);
            // display: block;
            color: #ca3935;
            font-weight: 600;
          }
        }
        &.active {
          background: #ca3935;
          border-radius: 0px 14px 0px 14px;
          a {
            color: #fff;
          }
        }
      }
    }
  }
  .btn-reg,
  .btn-tool-reg {
    cursor: pointer;
    color: #fff;
    padding: 8px 10px;
    background: linear-gradient(90deg, #ca3935 0%, #bb08ab 100%);
    border-radius: 4px;
    border: 1px solid #bb08ab;
    display: block;
    text-align: center;
  }
  .btn-reg {
    display: inline-block;
  }

  .header-btn-tool {
    float: right;
    margin: 10px 20px;
    .btn-tool-reg {
      float: left;
    }
    .btn-tool-zhen {
      a {
        color: #000000;

        display: inline-block;
      }
      a.lang-zh {
        padding: 8px 5px 8px 10px;
      }
      a.lang-en {
        padding: 8px 10px 8px 5px;
      }
      float: left;
      border-radius: 4px;
      border: 1px solid #000000;
    }
  }
}
