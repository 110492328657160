@use "sass:math";@import "~@/common/styles/variables.scss";
.agenda-list-container {
  .agenda-list-wrapper {
    .agenda-item {
      margin-top: 20px;
      .date {
        background-color: #fff;
        height: 40px;
        font-size: 20px;
        color: #ca3935;
        line-height: 40px;
        text-align: left;
        padding: 0px 15px;
        .txt {
          margin-left: 10px;
        }
        .subtxt {
          font-size: 14px;
        }
      }
      .list {
        padding: 0;
        margin: 0;

        .item {
          color: #fff;
          display: flex;
          margin-top: 10px;
          align-items: baseline;
          .datetime {
            width: 110px;
            display: flex;
            align-items: center;
            .dian {
              width: 5px;
              height: 5px;
              border-radius: 50%;
              border: 1px solid #fff;
              margin-right: 5px;
              background: #fff;
            }
          }
          .subitem {
            flex: 1;

            .txt {
              font-size: 14px;
              background: rgba(255, 255, 255, 0.25);
              padding: 15px;
              width: 100%;
              text-align: left;
              padding: 15px;
              margin-top: 10px;
              white-space: pre-line;
              .address {
                padding: 10px 0px;
                // font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}
