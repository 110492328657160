@use "sass:math";@import "~@/common/styles/variables.scss";
.swiper-contianer-multi {
  position: relative;
  --swiper-theme-color: #ca3935;
  --swiper-pagination-color: #ca3935;
  width: 100%;
  .swiper-slide {
    border: 1px solid #999999;
    border-radius: 8px;
    > img {
      width: 100%;
    }
  }
  .swiper-wrapper {
    padding-bottom: 20px;
  }
}
