@use "sass:math";@import "~@/common/styles/variables.scss";
.list-wrapper {
  .list-more {
    padding: 20px 5px;
  }
  .list {
    .list-item-wrapper {
      // border: 1px solid #000000;
      border-radius: 8px;
      padding: 0;
      margin-top: 20px;
      .list-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        background-color: #ffffff;
        .list-media {
          width: 100%;
          img {
            object-fit: cover;
            width: 100%;
            height: 17rem;
            border-radius: 8px 8px 0 0;
            display: block;
          }
        }
        .list-content {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          padding: $font20;
          .list-right-top {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            text-align: left;
            .list-title {
              padding: 0;
              font-weight: bold;
              margin-bottom: 10px;
              font-size: 16px;
              height: 42px;
            }
            .list-text {
              font-size: 14px;
              height: 36px;
            }
          }
        }
      }
    }
  }
}
