@use "sass:math";@import "~@/common/styles/variables.scss";
.item-tip {
  // padding: 30px 0px;
  text-align: center;
  .title {
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: $base-color-red;
  }
  > img {
    width: 100%;
  }
}
