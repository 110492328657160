@charset "UTF-8";
.swiper-contianer {
  position: relative;
  --swiper-theme-color: #ca3935;
  --swiper-pagination-color: #ca3935; /* 两种都可以 */
  width: 100%;
}
.swiper-contianer .swiper-slide > img {
  width: 100%;
}

.swiper-pagination-bullet-active {
  background: #ca3935;
}

.swiper-container-horizontal > .bullet-bottom.swiper-pagination-bullets {
  bottom: -20px;
}